body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@tailwind base;
@tailwind components;
@tailwind utilities;


ol{
  list-style: decimal;
}
.htmlPreview{
  line-height: 28px;
}
.htmlPreview ul, .htmlPreview ol{
  margin: 20px;
}
.htmlPreview li, .htmlPreview p{
  margin-bottom: 20px;
}
.htmlPreview a{
  color: #016BA3;
}

#tiledesk-container.open{
  z-index: 9999999999999 !important
}

#cust-care{
  z-index: 99999999;
}

.pdf-preview-container {
  width: 100%;
  max-width: 800px; /* Adjust as needed */
  margin: 0 auto;
}

.pdf-preview {
  border: 1px solid #ccc;
}

.image-style-side{
  float: inline-end;
}